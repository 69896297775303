import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public breadcrumbs: string[] = [];
  public inClientRoute = false;
  public element: any = {};

  constructor(public loginService: LoginService, public route: Router) { }

  public logout()
  {
    this.loginService.logout().subscribe(e => {this.route.navigate(['/'])});
  }

  ngOnInit() {

    this.route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((p: NavigationEnd) => {
      let router  = this.route.routerState.root;
      while (router.firstChild != null)
        router = router.firstChild;
      router.data.subscribe(e => {
        this.inClientRoute = false;
        if (!e.breadcrumb)
          this.breadcrumbs = ['Welcome'];
        else{
          this.breadcrumbs = e.breadcrumb.map(b => {
            if (b.startsWith(":"))
            {
              this.inClientRoute = true;
              this.element.id_client = e.client.id_client;
              let p = b.substr(1).split('.')
              let t = e;
              for (let i of p)
                t =t[i];
              return t;
            }
            return b;
          });
        }
      })
    });

  }

}
