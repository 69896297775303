import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { merge } from 'rxjs';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.sass'],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  private loginForm:FormGroup;

  public error:boolean = false;
  public hide:boolean = true;

  constructor(private loginService:LoginService, private fb:FormBuilder, private router:Router) {
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]

    })

   }

  ngOnInit() {
    merge(this.loginForm.get('login').valueChanges, this.loginForm.get('password').valueChanges).subscribe(e => {
      this.error = false;
    })
  }

  private onSubmit():void
  {
    if (this.loginForm.get('login').errors == null && this.loginForm.get('password').errors == null)
    {

      let sub = this.loginService.login(this.loginForm.get('login').value, this.loginForm.get('password').value,).subscribe(data => {
        this.router.navigate([(this.loginService.redirectUrl == '/' ) ? '/client' : this.loginService.redirectUrl])
        sub.unsubscribe();
      }, (err) => {
        this.error = true;

      });
    }
    
  }
  


}
