import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from '../services/login.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
constructor(private authService: LoginService, private router: Router){}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }
  canLoad(route: Route): Observable<boolean>| boolean {
    return this.checkLogin(location.pathname);
  }
  checkLogin(url: string): Observable<boolean> | boolean {
    if (url == '/' && this.authService.isLogged === false)
      return true;
    this.authService.redirectUrl = url;
    if (this.authService.isLogged) { return true; }
    return this.authService.isLogin().pipe(
      catchError((e) => {
        this.router.navigate(['/']);
        return of(false);}),
      map((d) =>{
        if (d)
        {
          if (url == '/')
            this.router.navigate(['/client'])
          return true;
        }
      }));
  }
}
