import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import {tap, catchError} from 'rxjs/operators';


export class User{
  name:string;
  id:number;
  acls:Array<string>;
  group:string;
  public constructor(user:any)
  {
      this.name = user.name;
      this.id = user.id;
      this.acls = user.acls;
      this.group = user.group;
  }
  public hasAcls(acls:Array<string>)
  {
      var length = acls.length;
      var count = 0;
      for (var i = 0; i < length ; i++)
      {
          if (this.acls.indexOf(acls[i]) != -1)
              ++count;
      }
      return (count == length);
  }

  /**
   * profile - string
   */
  public hasGroup(group:string)
  {
  return (this.group == group);
  }

  /**
   * acl - string
   */
  public hasAcl(acl:string)
  {
  return (this.acls.indexOf(acl) != -1);
  }
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  public redirectUrl:string;
  public isLogged:boolean = undefined;
  public user:User;

  constructor(private httpClient:HttpClient) {

  }

  public logout():Observable<any>
  {
   
    localStorage.removeItem('access_token');
    this.isLogged = false;
    this.user = undefined;
    return of({data: "ok"});
  }

  public isLogin():Observable<any>
  {
    this.isLogged = false;
    let httpOptions = { headers: new HttpHeaders({
      'Authorization':'Bearer ' + localStorage.getItem('access_token')
    })};
    return this.httpClient.get('/login/checktoken', httpOptions).pipe(
      catchError(this.handleError), 
      tap((value) => {
        this.user = new User(value.data.user);
        this.isLogged = true;
      })
    );

  }

  public login(user:string, password:string):Observable<any>
  {
    let httpOptions = { headers: new HttpHeaders({
      'Content-Type':'application/json'
    })};
    return this.httpClient.post('/login/do', {user:user, password: password}, httpOptions).pipe(
      catchError(this.handleError), 
      tap((value) => {
        this.user = new User(value.data.user);
        localStorage.setItem('access_token', value.data.token);
        this.isLogged = true;
      })
    );
  }



  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      this.isLogged = false;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };

}
